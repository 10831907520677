import { defineComponent } from "vue";

import SiteForm from "./../SiteForm/SiteForm.vue";

export default defineComponent({
  name: "AddSiteForm",
  props: {},
  components: { SiteForm },
  setup() {
    return {};
  },
});
